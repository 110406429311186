
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "adminvars";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
//@import "bower_";

.dv {
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dv-header {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-title {
    flex: 2;
    padding-right: 15px;
  }
  &-pre {
    margin-right: 15px;
  }
  &-select {
    display: block;
    width: 100%;
  }
  &-columns {
    flex: 2;
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
  }
  &-operators {
    flex: 2;
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
  }
  &-search {
    flex: 4;
    padding-right: 15px;
  }
  &-input {
    display: block;
    width: 100%;
  }
  &-submit {
    flex: 1;
  }
  &-btn {
    display: block;
    width: 100%;
  }
}

.dv-footer {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  align-items: center;
  &-input {
    width: 40px;
    text-align: center;
  }
  &-btn {
    padding: 2px 10px;
  }
}

.dv-footer-item {
  display: flex;
  justify-content: space-between;
}
.dv-footer-sub {
  padding-left: 15px;
}